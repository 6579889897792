html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.monaco-hover {
    z-index: 99999;
}

#loading {
    opacity: 1;
}

#loading.loaded {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.react-draggable {
    height: 640px;
    width: 620px;
}
